import React, { useState, useEffect, useRef, useMemo } from 'react';

// LoadPanel
import { LoadPanel } from 'devextreme-react/load-panel';

// Toast
import { Toast } from 'devextreme-react/toast';

// Grid
import DataGrid, {
  Column, FilterRow, HeaderFilter, FilterPanel, FilterBuilderPopup,
  GroupPanel, Grouping, SearchPanel, Sorting, Editing, Lookup, Toolbar, Item, RequiredRule,
  Scrolling, Pager, Paging, StateStoring, ColumnChooser, ColumnFixing, Format, Selection, Summary, TotalItem,
  Export, Search, ColumnChooserSearch, ColumnChooserSelection
} from 'devextreme-react/data-grid';
import { GroupItem as GroupItemGrid } from 'devextreme-react/data-grid'

//Form
import Form, { GroupItem, Label, TabbedItem, TabPanelOptions, Tab } from 'devextreme-react/form';

import { Auth, API, Storage } from 'aws-amplify';

// Ruteador
import { Route, Link, useHistory } from "react-router-dom";

// Iconos
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBoxOpenFull } from "@fortawesome/pro-duotone-svg-icons";
import { faArrowsRotate } from "@fortawesome/pro-solid-svg-icons";
import { faSave } from "@fortawesome/pro-solid-svg-icons";
import { faLightbulbGear } from "@fortawesome/pro-solid-svg-icons";
import { faFilter } from "@fortawesome/pro-solid-svg-icons";

import './ProductCluster.css';

import '../ListStyle.css';

import DataSource from 'devextreme/data/data_source';

import { Workbook } from 'exceljs';
import { saveAs } from 'file-saver';
import { exportDataGrid } from 'devextreme/excel_exporter'

export default function ProductCluster(props) {
  const history = useHistory();

  const [permissionLevel, setPermissionLevel] = useState(props.nivel);
  const [usuario, setUsuario] = useState(props.usuario);

  const [productClusterPayload, setProductClusterPayload] = useState({
  });

  const [updateCount, setUpdateCount] = useState(0);

  const [itemList, setItemList] = useState([]);
  const [clusterList, setClusterList] = useState([]);
  const [clusterTypeList, setClusterTypeList] = useState([]);
  const [departamentos, setDepartamentos] = useState([]);
  const [itemType, setItemType] = useState([]);
  const [categorias, setCategorias] = useState([]);
  const [exclusivo, setExclusivo] = useState([]);
  const [proveedores, setProveedores] = useState([]);

  const [activateSaveButton, setActivateSaveButton] = useState(false);
  const [activateUpdateGrid, setActivateUpdateGrid] = useState((props.nivel === 'Editar' || props.nivel === 'Procesar' ? true : false));

  const [autoExpandAll, setAutoExpandAll] = useState(true);

  const [visibleLoadPanel, setVisibleLoadPanel] = useState(false);

  const [visibleToast, setVisibleToast] = useState(false);
  const [messageToast, setMessageToast] = useState('');
  const [typeToast, setTypeToast] = useState('success');

  //Ref para el form
  const [formDatosGeneralesRef, setFormDatosGeneralesRef] = useState(React.createRef());

  //Ref para el grid Tablero de Control
  const [gridProductClusterRef, setGridProductClusterRef] = useState(React.createRef());

  function getOrderDay(rowData) {
    return (new Date(rowData.OrderDate)).getDay();
  };

  const filterBuilder = useMemo(() => {
    return {
      customOperations: [{
        name: 'weekends',
        caption: 'Weekends',
        dataTypes: ['date'],
        icon: 'check',
        hasValue: false,
        calculateFilterExpression: () => [[getOrderDay, '=', 0], 'or', [getOrderDay, '=', 6]],
      }],
      allowHierarchicalFields: true,
    };
  }, []);

  const filterBuilderPopupPosition = useMemo(() => {
    return {
      of: window,
      at: 'top',
      my: 'top',
      offset: { y: 10 },
    };
  }, []);

  const allowedPageSizes = useMemo(() => {
    return [5, 10, 15, 20, 50, 100, 150, 'all'];
  }, []);

  const exportFormats = useMemo(() => {
    return ['xlsx'];
  }, []);

  const onAutoExpandAllChanged = React.useCallback(() => {
    setAutoExpandAll(!autoExpandAll)
  }, [autoExpandAll]);

  var toastPosition = useMemo(() => {
    return {
      my: 'top center',
      at: 'top center',
      of: window,
      offset: '0 130'
    }
  }, []);

  try {
    var departamentoEditorOptions = useMemo(() => {
      return {
        items: departamentos,
        displayExpr: 'name',
        valueExpr: 'value',
        searchEnabled: true,
        value: (productClusterPayload.custitem_al_ecommerce_departamento ? productClusterPayload.custitem_al_ecommerce_departamento : ''),
        width: '100%',
        disabled: false,
        showClearButton: true
      };
    }, [productClusterPayload.custitem_al_ecommerce_departamento, departamentos]);

  }
  catch (e) {
    var departamentoEditorOptions = {
      items: departamentos,
      displayExpr: 'name',
      valueExpr: 'value',
      searchEnabled: true,
      value: '',
      width: '100%',
      showClearButton: true
    };
  }

  try {
    var categoriaEditorOptions = useMemo(() => {
      return {
        items: categorias,
        displayExpr: 'name',
        valueExpr: 'value',
        searchEnabled: true,
        value: (productClusterPayload.custitem_al_ecommerce_categoria ? productClusterPayload.custitem_al_ecommerce_categoria : ''),
        width: '100%',
        disabled: false,
        showClearButton: true
      };
    }, [productClusterPayload.custitem_al_ecommerce_categoria, categorias]);

  }
  catch (e) {
    var categoriaEditorOptions = {
      items: categorias,
      displayExpr: 'name',
      valueExpr: 'value',
      searchEnabled: true,
      value: '',
      width: '100%',
      showClearButton: true
    };
  }

  try {
    var exclusivoEditorOptions = useMemo(() => {
      return {
        items: exclusivo,
        displayExpr: 'name',
        valueExpr: 'value',
        searchEnabled: true,
        value: (productClusterPayload.custitem_efx_cmp_exclusivo ? productClusterPayload.custitem_efx_cmp_exclusivo : ''),
        width: '100%',
        disabled: false,
        showClearButton: true
      };
    }, [productClusterPayload.custitem_efx_cmp_exclusivo, exclusivo]);

  }
  catch (e) {
    var exclusivoEditorOptions = {
      items: exclusivo,
      displayExpr: 'name',
      valueExpr: 'value',
      searchEnabled: true,
      value: '',
      width: '100%',
      showClearButton: true
    };
  }

  var proveedorDataSource = useMemo(() => {
    return new DataSource({
      paginate: true,
      store: {
        type: "array",
        key: "value",
        data: proveedores
      }
    });
  }, [proveedores]);

  try {
    var vendorEditorOptions = useMemo(() => {
      return {
        dataSource: proveedorDataSource,
        displayExpr: 'name',
        valueExpr: 'value',
        searchEnabled: true,
        value: (productClusterPayload.vendor ? productClusterPayload.vendor : ''),
        width: '100%',
        disabled: false,
        showClearButton: true
      };
    }, [productClusterPayload.vendor, proveedorDataSource]);

  }
  catch (e) {
    var vendorEditorOptions = {
      items: proveedores,
      displayExpr: 'name',
      valueExpr: 'value',
      searchEnabled: true,
      value: '',
      width: '100%',
      showClearButton: true
    };
  }

  const getItemList = React.useCallback(async () => {
    var custitem_al_ecommerce_categoria = productClusterPayload.custitem_al_ecommerce_categoria;
    var custitem_al_ecommerce_departamento = productClusterPayload.custitem_al_ecommerce_departamento;
    var custitem_efx_cmp_exclusivo = productClusterPayload.custitem_efx_cmp_exclusivo;
    var vendor = productClusterPayload.vendor;

    var filters = [];

    if (custitem_al_ecommerce_categoria) {
      filters.push(['custitem_al_ecommerce_categoria', 'is', custitem_al_ecommerce_categoria])
    }
    if (custitem_al_ecommerce_departamento) {
      if (filters.length > 0) {
        filters.push('and');
      }

      filters.push(['custitem_al_ecommerce_departamento', 'is', custitem_al_ecommerce_departamento])
    }
    if (custitem_efx_cmp_exclusivo) {
      if (filters.length > 0) {
        filters.push('and');
      }

      filters.push(['custitem_efx_cmp_exclusivo', 'is', custitem_efx_cmp_exclusivo])
    }
    if (vendor) {
      if (filters.length > 0) {
        filters.push('and');
      }

      filters.push(['vendor', 'is', vendor])
    }

    let apiName = 'portalvinoteca';
    let path = '/procesaproductcluster';

    let myInit = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`
      },
      body: {
        action: 'list',
        filters: filters,
        q: window.btoa(JSON.parse(JSON.stringify(await Auth.currentAuthenticatedUser())).username)
      }
    }

    var itemsGet = await API.post(apiName, path, myInit);

    setItemList(itemsGet.data.items);

    setClusterList(itemsGet.data.clusters);

    setClusterTypeList(itemsGet.data.clusterTypes);
  }, [productClusterPayload]);

  const getDepartmentList = React.useCallback(async () => {
    let apiName = 'portalvinoteca';
    let path = '/procesahelper';

    let myInit = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`
      },
      body: {
        action: 'departmentList',
        q: window.btoa(JSON.parse(JSON.stringify(await Auth.currentAuthenticatedUser())).username)
      }
    }

    var departmentsGet = await API.post(apiName, path, myInit);

    setDepartamentos(departmentsGet.data);

  }, []);

  const getCategoryList = React.useCallback(async (Departamento) => {
    let apiName = 'portalvinoteca';
    let path = '/procesahelper';

    let myInit = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`
      },
      body: {
        action: 'categoryList',
        filter: ['custrecord_al_ecommerce_categoria_depto', 'is', Departamento],
        q: window.btoa(JSON.parse(JSON.stringify(await Auth.currentAuthenticatedUser())).username)
      }
    }

    var categoriesGet = await API.post(apiName, path, myInit);

    setCategorias(categoriesGet.data);

  }, []);

  const getExclusiveList = React.useCallback(async () => {
    let apiName = 'portalvinoteca';
    let path = '/procesahelper';

    let myInit = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`
      },
      body: {
        action: 'exclusiveList',
        q: window.btoa(JSON.parse(JSON.stringify(await Auth.currentAuthenticatedUser())).username)
      }
    }

    var exclusiveGet = await API.post(apiName, path, myInit);

    setExclusivo(exclusiveGet.data);

  }, []);

  const getVendorList = React.useCallback(async () => {
    let apiName = 'portalvinoteca';
    let path = '/procesahelper';

    let myInit = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`
      },
      body: {
        action: 'vendorList',
        q: window.btoa(JSON.parse(JSON.stringify(await Auth.currentAuthenticatedUser())).username)
      }
    }

    var vendorGet = await API.post(apiName, path, myInit);

    setProveedores(vendorGet.data);
  }, []);

  const getItemTypeList = React.useCallback(async () => {
    let apiName = 'portalvinoteca';
    let path = '/procesahelper';

    let myInit = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`
      },
      body: {
        action: 'itemTypeList',
        q: window.btoa(JSON.parse(JSON.stringify(await Auth.currentAuthenticatedUser())).username)
      }
    }

    var itemTypeGet = await API.post(apiName, path, myInit);

    setItemType(itemTypeGet.data);
  }, []);

  const refreshButton = React.useCallback(async () => {
    if (updateCount > 0) {
      if (!window.confirm('Existen ' + updateCount + ' cambios pendientes. ¿Desea descartarlos?'))
        return;
    }

    setUpdateCount(0);
    setActivateSaveButton(false);

    if (gridProductClusterRef.current) {
      var gridProductCluster = gridProductClusterRef.current.instance;

      setVisibleLoadPanel(true);

      await getItemList();

      setVisibleLoadPanel(false);

      gridProductCluster.refresh();
    }
  }, [getItemList, gridProductClusterRef, updateCount]);

  const filterButton  = React.useCallback(async () => {
    if (gridProductClusterRef.current) {
      var gridProductCluster = gridProductClusterRef.current.instance;

      gridProductCluster.clearFilter();

      if (itemList.length > 0) {
        var firstItem = itemList[0];

        try{
          var columns = Object.keys(firstItem);

          for (var columnIndex in columns) {
            var singleColumn = columns[columnIndex];

            if (singleColumn.indexOf('c_') === 0) {
              gridProductCluster.columnOption(singleColumn, "filterValue", false);
            }
          }
        }
        catch(e) {
        }
      }
    }
  }, [gridProductClusterRef, itemList]);

  const saveButton = React.useCallback(async () => {
    if (updateCount === 0) {
      setVisibleToast(true);
      setMessageToast('No hay cambios pendientes por guardar');
      setTypeToast('error');

      return;
    }

    try {
      setVisibleLoadPanel(true);

      var updateList = [];

      for (var itemListIndex in itemList) {
        var singleItemList = itemList[itemListIndex];
        var productClusterAssociation = {};

        if (singleItemList.wasupdated) {
          for (var clusterListIndex in clusterList) {
            var singleClusterList = clusterList[clusterListIndex];

            productClusterAssociation["c_" + singleClusterList.internalid] = singleItemList["c_" + singleClusterList.internalid]
          }

          updateList.push({
            internalid: singleItemList.productClusterId,
            itemid: singleItemList.internalid,
            custrecord_al_por_pro_clu_item: parseInt(singleItemList.internalid),
            custrecord_al_por_proclu_association: JSON.stringify(productClusterAssociation),
            custitem_gv_rlx_startsupplydate: singleItemList.custitem_gv_rlx_startsupplydate,
            custitem_gv_rlx_endsupplydate: singleItemList.custitem_gv_rlx_endsupplydate,
            custitem_efx_cmp_tip_art: singleItemList.custitem_efx_cmp_tip_art,
            usuario: usuario
          });
        }
      }

      let apiName = 'portalvinoteca';
      let path = '/procesaproductcluster';

      let myInit = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`
        },
        body: {
          action: 'postData',
          data: updateList,
          q: window.btoa(JSON.parse(JSON.stringify(await Auth.currentAuthenticatedUser())).username)
        }
      }

      var payloadPost = await API.post(apiName, path, myInit);

      setActivateSaveButton(false);
      setItemList([]);
      setUpdateCount(0);

      setVisibleLoadPanel(false);

      if (payloadPost.success) {
        setVisibleToast(true);
        setMessageToast('Se envió la petición a Netsuite correctamente.');
        setTypeToast('success')
      }
      else if (!payloadPost.success) {
        setVisibleToast(true);
        setMessageToast(payloadPost.message);
        setTypeToast('error')
      }
    }
    catch (e) {
      setVisibleLoadPanel(false);

      if (e === 'No current user') {
        history.push('/');

        window.location.reload();
      }
    }
  }, [updateCount, itemList, clusterList, history, usuario]);

  const processButton = React.useCallback(async () => {
    try {
      setVisibleLoadPanel(true);

      let apiName = 'portalvinoteca';
      let path = '/procesaproductcluster';

      let myInit = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`
        },
        body: {
          action: 'processData',
          q: window.btoa(JSON.parse(JSON.stringify(await Auth.currentAuthenticatedUser())).username)
        }
      }

      var payloadPost = await API.post(apiName, path, myInit);

      setVisibleLoadPanel(false);

      if (payloadPost.success) {
        setVisibleToast(true);
        setMessageToast('Se envió la petición a Netsuite correctamente.');
        setTypeToast('success')
      }
      else if (!payloadPost.success) {
        setVisibleToast(true);
        setMessageToast(payloadPost.message);
        setTypeToast('error')
      }
    }
    catch (e) {
      setVisibleLoadPanel(false);

      if (e === 'No current user') {
        history.push('/');

        window.location.reload();
      }
    }
  }, [history]);

  useEffect(() => {
    async function initialize() {

      setVisibleLoadPanel(true);

      await getDepartmentList();

      await getExclusiveList();

      await getVendorList();

      await getItemTypeList();

      setVisibleLoadPanel(false);
    }

    initialize();
  }, [getDepartmentList, getCategoryList, getExclusiveList, getVendorList, getItemTypeList]);

  const onHidingToast = React.useCallback(() => {
    setVisibleToast(false);
  }, []);

  const onRowUpdating = React.useCallback((e) => {
    e.newData.wasupdated = true;

    var currentCount = updateCount;

    currentCount++;

    setActivateSaveButton(true);

    setUpdateCount(currentCount++);

  }, [updateCount]);

  const calculaTotalizadores = React.useCallback((options) => {
    if (options.summaryProcess === 'start') {
      options.totalValue = 0;
    }
    else if (options.summaryProcess === 'calculate') {
      if (options.value[options.name] === true)
        options.totalValue += 1;
    }
  }, []);

  const onFormFieldChanged = React.useCallback(async (e) => {
    var dataField = e.dataField;
    var value = e.value;

    if (formDatosGeneralesRef.current) {
      var formDatosGenerales = formDatosGeneralesRef.current.instance;

      if (e.dataField === 'custitem_al_ecommerce_departamento') {
        formDatosGenerales.updateData('custitem_al_ecommerce_categoria', undefined);

        if (e.value) {
          await getCategoryList(e.value);
        }
      }
    }
  }, [formDatosGeneralesRef, getCategoryList]);

  function onExporting(e) {
    const workbook = new Workbook();
    const worksheet = workbook.addWorksheet('Datos');

    exportDataGrid({
      component: e.component,
      worksheet,
      autoFilterEnabled: true,
    }).then(() => {
      workbook.xlsx.writeBuffer().then((buffer) => {
        saveAs(new Blob([buffer], { type: 'application/octet-stream' }), 'Datos.xlsx');
      });
    });
    e.cancel = true;
  };
  
  return (
    <div>
      <div class="bg-gray-200 m-2">
        <h1 class="font-sans inline-block text-xl font-bold text-gray-700 leading-8 align-top mb-1"><FontAwesomeIcon icon={faBoxOpenFull} size="lg" />Product-Cluster</h1>
      </div>

      <LoadPanel
        shadingColor="rgba(0,0,0,0.4)"
        visible={visibleLoadPanel}
        showIndicator={true}
        message="Por favor espere..."
        shading={true}
        showPane={true}
        hideOnOutsideClick={false}
      />

      <Toast
        visible={visibleToast}
        message={messageToast}
        type={typeToast}
        onHiding={onHidingToast}
        displayTime={5000}
        width={'auto'}
        position={toastPosition}
      />

      <div class="bg-white p-3 shadow-xl">
        <button onClick={refreshButton} type="submit" class="mb-4 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">
          <FontAwesomeIcon icon={faArrowsRotate} /> Actualizar
        </button>

        <button onClick={filterButton} type="submit" class="ml-4 mb-4 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">
          <FontAwesomeIcon icon={faFilter} /> Filtrar productos no asociados
        </button>

        {activateSaveButton.toString() === 'true' && (permissionLevel === 'Editar' || permissionLevel === 'Procesar') ?
          (
            <button onClick={saveButton} type="submit" class="ml-4 mb-4 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">
              <FontAwesomeIcon icon={faSave} /> Guardar Cambios
            </button>
          ) : (
            <div class="inline-block"></div>
          )}

        {permissionLevel === 'Procesar' ?
          (
            <button onClick={processButton} type="submit" class="ml-4 mb-4 bg-slate-500 hover:bg-slate-700 text-white font-bold py-2 px-4 rounded">
              <FontAwesomeIcon icon={faLightbulbGear} /> Procesar Datos
            </button>
          ) : (
            <div class="inline-block"></div>
          )}

        <Form
          id="datosGenerales"
          labelMode={'outside'}
          formData={productClusterPayload}

          showColonAfterLabel={false}
          labelLocation={'left'}
          colCount={1}

          ref={formDatosGeneralesRef}

          onFieldDataChanged={onFormFieldChanged}
        >
          <GroupItem caption="Filtros" colCount={2}>
            <Item dataField="custitem_al_ecommerce_departamento" editorType="dxSelectBox" editorOptions={departamentoEditorOptions} >
              <Label text={"Departamento"} />
            </Item>
            <Item dataField="custitem_al_ecommerce_categoria" editorType="dxSelectBox" editorOptions={categoriaEditorOptions} >
              <Label text={"Categoría"} />
            </Item>
            <Item dataField="custitem_efx_cmp_exclusivo" editorType="dxSelectBox" editorOptions={exclusivoEditorOptions} >
              <Label text={"Exclusivo"} />
            </Item>
            <Item dataField="vendor" editorType="dxSelectBox" editorOptions={vendorEditorOptions} >
              <Label text={"Proveedor"} />
            </Item>
          </GroupItem>
          <GroupItem caption="Productos" colCount={2}>
            <DataGrid
              id="gridProductCluster"
              columnsAutoWidth={true}
              filterBuilder={filterBuilder}
              dataSource={itemList}
              allowColumnReordering={true}
              allowColumnResizing={true}
              columnResizingMode="widget"
              showBorders={true}
              rowAlternationEnabled={true}
              showRowLines={true}
              showColumnLines={true}
              ref={gridProductClusterRef}
              onRowUpdating={onRowUpdating}
              filterSyncEnabled={true}
              onExporting={onExporting}
            >
              <Scrolling rowRenderingMode='virtual'></Scrolling>
              <Paging defaultPageSize={50} />
              <Pager
                visible={true}
                allowedPageSizes={allowedPageSizes}
                displayMode='compact'
                showPageSizeSelector={true}
                showInfo={true}
                showNavigationButtons={true} />
              <FilterRow visible={true} />
              <FilterPanel visible={true} />
              <FilterBuilderPopup position={filterBuilderPopupPosition} />

              <HeaderFilter visible={true} />

              <GroupPanel visible={true} />
              <SearchPanel visible={true} />
              <ColumnFixing enabled={true} />
              <ColumnChooser
                enabled={true}
                mode='select'
              >
                <ColumnChooserSearch
                  enabled={true}
                />

                <ColumnChooserSelection
                  allowSelectAll={true}
                  selectByClick={true}
                  recursive={true}
                />
              </ColumnChooser>
              <Grouping autoExpandAll={autoExpandAll} />
              <Export enabled={true} />

              <Editing
                mode="cell"
                allowUpdating={activateUpdateGrid}
                allowAdding={false}
                allowDeleting={false}
                useIcons={true}
              />

              <Column dataField="internalid" caption="internal id" dataType="number" allowEditing={false} width={300} visible={false} />
              <Column dataField="productClusterId" caption="Product Cluster internal id" dataType="number" allowEditing={false} width={300} visible={false} />

              <Column dataField="wasupdated" caption="Act." dataType="boolean" allowEditing={false} width={100} visible={true} />
              <Column dataField="itemid" caption="Nombre/Número del Artículo" allowEditing={false} dataType="string" width={250} fixed={true} >
                <HeaderFilter>
                  <Search enabled={true} />
                </HeaderFilter>
              </Column>
              <Column dataField="displayname" caption="Nombre/Código para mostrar" allowEditing={false} dataType="string" width={400} fixed={true} >
                <HeaderFilter>
                  <Search enabled={true} />
                </HeaderFilter>
              </Column>
              <Column dataField="custitem_gv_rlx_startsupplydate" caption="Fecha Inicio Abastecimiento" dataType="date" allowEditing={activateUpdateGrid} width={220} visible={true} >
                <HeaderFilter>
                  <Search enabled={true} />
                </HeaderFilter>
                <RequiredRule message="Fecha Inicio Abastecimiento es requerido" />
              </Column>
              <Column dataField="custitem_gv_rlx_endsupplydate" caption="Fecha Fin Abastecimiento" dataType="date" allowEditing={activateUpdateGrid} width={220} visible={true} >
                <RequiredRule message="Fecha Fin Abastecimiento es requerido" />
              </Column>
              <Column dataField="custitem_efx_cmp_tip_art" dataType="string" caption="Tipo de Artículo" allowEditing={activateUpdateGrid} width={220} visible={true} >
                <Lookup dataSource={itemType} valueExpr="value" displayExpr="name" />
                <RequiredRule message="Tipo de Artículo es requerido" />
                <HeaderFilter>
                  <Search enabled={true} />
                </HeaderFilter>
              </Column>
              <Column dataField="custitem_al_ecommerce_categoria" caption="eCommerce Categoría" dataType="string" width={300} visible={false}>
                <HeaderFilter>
                  <Search enabled={true} />
                </HeaderFilter>
              </Column>
              <Column dataField="custitem_al_ecommerce_departamento" caption="eCommerce Departamento" dataType="string" width={300} visible={false}>
                <HeaderFilter>
                  <Search enabled={true} />
                </HeaderFilter>
              </Column>
              <Column dataField="custitem_al_ecommerce_familia" caption="eCommerce Familia" dataType="string" width={300} visible={false}>
                <HeaderFilter>
                  <Search enabled={true} />
                </HeaderFilter>
              </Column>

              <Column dataField="custitem_efx_cmp_exclusivo" caption="Exclusivo" dataType="string" width={300} visible={false}>
                <HeaderFilter>
                  <Search enabled={true} />
                </HeaderFilter>
              </Column>
              <Column dataField="vendor" caption="Proveedor Principal" dataType="string" width={300} visible={false}>
                <HeaderFilter>
                  <Search enabled={true} />
                </HeaderFilter>
              </Column>

              {
                clusterTypeList.map((clusterType, indexType) => (
                  <Column caption={clusterType} >

                    {
                      clusterList.map((cluster, indexCluster) => (
                        clusterType === cluster.custrecord_al_por_clu_type ? (<Column dataField={"c_" + cluster.internalid} caption={cluster.name} width={140} visible={true} />) : (<></>)
                      ))
                    }
                  </Column>
                ))
              }

              <Summary calculateCustomSummary={calculaTotalizadores} recalculateWhileEditing={true}>
                {
                  clusterList.map((cluster, indexCluster) => (
                    <TotalItem
                      name={"c_" + cluster.internalid}
                      summaryType="custom"
                      displayFormat="SKUs: {0}"
                      showInColumn={"c_" + cluster.internalid}
                    />
                  ))
                }
              </Summary>
            </DataGrid>
          </GroupItem>
        </Form>
      </div>
    </div>
  );
}